<template>
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-12">
                <h3>Clients</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="bg-light p-4">
                    <div class="mb-2">Create a new Client</div>
                    <b-form @submit.prevent="createClient">
                        <b-form-group class="mb-2">
                            <b-form-input
                                type="text"
                                required
                                placeholder="Username"
                                squared
                                v-model="newClient.user_name"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group>
                            <b-button type="submit" squared class="w-100" variant="success">Add</b-button>
                        </b-form-group>
                    </b-form>
                </div>
            </div>
            <div class="col-md-8">
                <div class="bg-light p-4">
                    <div class="mb-2">All Clients</div>

                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Client</th>
                            <th scope="col">Cash Balance</th>
                            <th scope="col">Gain/Loss</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="client in clients" :key="client.id">
                            <td>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="">{{ client.client.user_name }}</span>
                                </div>
                            </td>
                            <td>
                                €{{ client.client.balance_whole }}
                            </td>
                            <td>
                                <span :class="getChangeClass(client.gain_or_loss)"> {{ getChangeText(client.gain_or_loss) }}</span>
                            </td>
                            <td>
                                <div>
                                    <b-dropdown id="dropdown-offset" offset="25" text="Action" class="m-2 rounded-0">
                                        <b-dropdown-item @click.prevent="() => $router.push({name: 'client-stocks', params: {id: client.id}})">
                                            <em class="fas fa-eye"></em> View Stocks
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceFormatter from "../mixins/PriceFormatter";
import ErrorHandlers from "../mixins/ErrorHandlers";

export default {
    name: "ClientPage",
    mixins: [PriceFormatter, ErrorHandlers],
    data() {
        return {
            clients: [],
            newClient: {
                user_name: '',
            },
        };
    },
    async created() {
       await this.initialiseClients();
    },
    methods: {
        async initialiseClients() {
            const {data: {data}} = await axios.get('/api/clients/purchases');

            this.clients = data;
        },
        async createClient() {
            if (this.newClient.user_name.trim().length < 2) {
                alert('Form invalid. Cannot add new client');

                return;
            }

            try {
                await axios.post('/api/clients', {
                    user_name: this.newClient.user_name,
                });

                await this.initialiseClients();

                this.newClient = {
                    user_name: '',
                };
            } catch (e) {
                this.handleError('Unable to create client for:', e);
            }
        },
        getErrorMessages(errors) {
            let errorMessages = [];

            for (const key in errors) {
                errorMessages = [
                    ...errorMessages,
                    ...errors[key],
                ]
            }

            return errorMessages
        },
    },
};
</script>

<style scoped>

</style>
