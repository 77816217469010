<template>
    <div class="container mt-3">
        <b-modal
            id="edit-price-modal"
            ref="modal"
            title="Update Stock Unit price"
            size="sm"
            hide-footer
            hide-backdrop
        >
            <form ref="form" @submit.stop.prevent="updateStockPrice">
                <b-form-group class="mb-2">
                    <b-input-group prepend="€">
                        <b-form-input
                            type="number"
                            placeholder="Unit price"
                            squared
                            min="0.1"
                            step=".01"
                            pattern="^\d*(\.\d{0,2})?$"
                            v-model="editableStock.unit_price_whole"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group>
                    <b-button type="submit" squared class="w-100" variant="primary">Update</b-button>
                </b-form-group>
            </form>
        </b-modal>
        <div class="row">
            <div class="col-md-12">
                <h3>Stock List</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bg-light p-4">
                            <div class="mb-2">Create a new Stock</div>
                            <b-form @submit.prevent="createStock">
                                <b-form-group class="mb-2">
                                    <b-form-input
                                        type="text"
                                        required
                                        placeholder="Company name"
                                        squared
                                        v-model="newStock.company_name"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group class="mb-2">
                                    <b-input-group prepend="€">
                                        <b-form-input
                                            type="number"
                                            placeholder="Unit price"
                                            squared
                                            min="0.1"
                                            step=".01"
                                            pattern="^\d*(\.\d{0,2})?$"
                                            v-model="newStock.unit_price_whole"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group>
                                    <b-button type="submit" squared class="w-100" variant="primary">Add</b-button>
                                </b-form-group>
                            </b-form>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="bg-light p-4">
                            <div class="mb-2">Purchase Stock</div>
                            <b-form @submit.prevent="purchaseStock">
                                <b-form-group class="mb-2">
                                    <select v-model="newPurchase.stock_id" required class="w-100">
                                        <option selected value="">Choose stock</option>
                                        <option v-for="stock in stocks" :value="stock.id" :key="stock.id">
                                            {{ stock.company_name }} (€{{ stock.unit_price_whole }})
                                        </option>
                                    </select>
                                </b-form-group>
                                <b-form-group class="mb-2">
                                    <select v-model="newPurchase.client_id" required class="w-100">
                                        <option selected value="">Choose client</option>
                                        <option v-for="client in clients" :value="client.id" :key="client.id">
                                            {{ client.user_name }} (€{{ client.balance_whole }})
                                        </option>
                                    </select>
                                </b-form-group>

                                <b-form-group class="mb-2">
                                    <b-form-input
                                        required
                                        type="number"
                                        placeholder="Volume"
                                        squared
                                        min="1"
                                        step="1"
                                        v-model="newPurchase.volume"
                                        class="w-100"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group>
                                    <b-button type="submit" squared class="w-100" variant="primary">Add</b-button>
                                </b-form-group>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="bg-light p-4">
                    <div class="mb-2">All Stocks</div>

                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Company</th>
                            <th scope="col">Unit price</th>
                            <th scope="col">Updated at</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="stock in stocks" :key="stock.id">
                            <td>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="">{{ stock.company_name }}</span>
                                    <b-button
                                        size="sm"
                                        variant="light"
                                        title="Present this report."
                                        class="mx-5"
                                        @click.prevent="() => null"
                                    >
                                        <em class="fas fa-play-circle fa-lg"></em>
                                    </b-button>
                                </div>
                            </td>
                            <td>
                                €{{ stock.unit_price_whole }}
                            </td>
                            <td>
                                {{ stock.updated_at_formatted }}
                            </td>
                            <td>
                                <div>
                                    <b-dropdown id="dropdown-offset" offset="25" text="Action" class="m-2 rounded-0">
                                        <b-dropdown-item href="#" @click="() => editStock(stock)">
                                            <em class="fas fa-pen"></em> Update unit price
                                        </b-dropdown-item>
                                        <b-dropdown-item href="#" @click="() => deleteStock(stock)">
                                            <em class="fas fa-trash"></em> Delete
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorHandlers from "../mixins/ErrorHandlers";

export default {
    name: "StockPage",
    mixins: [ErrorHandlers],
    data() {
        return {
            stocks: [],
            clients: [],
            newStock: {
                company_name: '',
                unit_price_whole: 0.00,
            },
            editableStock: {
                unit_price_whole: 0.00,
            },
            newPurchase: {
                stock_id: null,
                client_id: null,
                volume: 0,
            },
        };
    },
    async created() {
        await this.initialiseStocks();

        const {data: {data: clients}} = await axios.get('/api/clients');

        this.clients = clients;
    },
    methods: {
        async initialiseStocks() {
            const {data: {data}} = await axios.get('/api/stocks');

            this.stocks = data;
        },
        async updateStockPrice() {
            if (!this.editableStock.id || this.editableStock.unit_price_whole < 0.1) {
                alert('Form invalid. Cannot update stock unit price');

                return;
            }

            try {
                const unitPrice = this.editableStock.unit_price_whole * 100;

                await axios.put(`/api/stocks/${this.editableStock.id}`,  {
                    unit_price: unitPrice,
                });

                this.$bvModal.hide('edit-price-modal');

                this.editableStock = {
                    id: null,
                    unit_price_whole: 0.00,
                };

                await this.initialiseStocks();
            } catch (e) {
                this.handleError('Unable to update stock price for:', e);
            }
        },
        async createStock() {
            if (!this.newStock.company_name.trim() || this.newStock.unit_price_whole < 0.1) {
                alert('Form invalid. Cannot add new stock');

                return;
            }

            try {
                const unitPrice = this.newStock.unit_price_whole * 100;

                const {data: {data}} = await axios.post('/api/stocks', {
                    company_name: this.newStock.company_name,
                    unit_price: unitPrice,
                });

                this.stocks.push(data);

                this.newStock = {
                    company_name: '',
                    unit_price_whole: 0.00,
                };
            } catch (e) {
                this.handleError('Unable to create stock for:', e);
            }
        },
        async purchaseStock() {
            if (!this.newPurchase.stock_id || !this.newPurchase.client_id  || this.newPurchase.volume < 1 ) {
                alert('Form invalid. Cannot add new stock');

                return;
            }

            try {
                const {data: {data}} = await axios.post('/api/purchases', {
                    stock_id: this.newPurchase.stock_id,
                    client_id: this.newPurchase.client_id,
                    volume: this.newPurchase.volume,
                });

                console.log({data});

                alert('Purchase successfully made for volume: '+ this.newPurchase.volume);

                this.newPurchase = {
                    stock_id: null,
                    client_id: null,
                    volume: 0,
                };
            } catch (e) {
                this.handleError('Unable to purchase stock for:', e);
            }
        },
        editStock(stock) {
          this.editableStock = {
              id: stock.id,
              unit_price_whole: stock.unit_price_whole,
          };

          this.$bvModal.show('edit-price-modal');
        },
        async deleteStock(stock) {
            if (!confirm(`Are you sure you want to delete ${stock.company_name}'s stock?`)) {
                return;
            }

            try {
                await axios.delete(`/api/stocks/${stock.id}`);

                await this.initialiseStocks();
            } catch (e) {
                const errorMessages = this.getErrorMessages(e.response.data.errors);

                alert('Unable to delete stock for: \n \n' + errorMessages.join('\n'));
            }
        },
    },
};
</script>

<style scoped>

</style>
