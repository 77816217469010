export default {
    methods: {
        getErrorMessages(errors) {
            let errorMessages = [];

            for (const key in errors) {
                errorMessages = [
                    ...errorMessages,
                    ...errors[key],
                ]
            }

            return errorMessages
        },
        handleError(message, e) {
            let errorMessages;

            if (422 === e.response.status) {
                errorMessages = this.getErrorMessages(e.response.data.errors);
            } else {
                errorMessages = [e.response.data.message];
            }

            alert(message + '\n \n' + errorMessages.join('\n'));
        },
    },
};
