export default {
    methods: {
        getChangeClass(value) {
            if (value > 0.0) {
                return 'text-success';
            } else if (value < 0.0) {
                return 'text-danger';
            }

            return 'text-dark';
        },
        getChangeText(value, withoutPositiveSign) {
            if (value < 0.0) {
                return `- €${Math.abs(value).toFixed(2)}`
            } else if (value == 0.0) {
                return `€${Math.abs(value).toFixed(2)}`;
            }

            return withoutPositiveSign
                ? `€${Math.abs(value).toFixed(2)}`
                : `+ €${Math.abs(value).toFixed(2)}`;
        },
    },
};
