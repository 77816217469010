import Vue from 'vue';
import VueRouter from 'vue-router';

import StockPage from './components/StockPage';
import ClientPage from "./components/ClientPage";
import ClientStocksPage from "./components/ClientStocksPage";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'home',
            component: StockPage,
        },
        {
            path: '/clients/:id/stocks',
            name: 'client-stocks',
            component: ClientStocksPage,
        },
        {
            path: '/clients',
            name: 'clients',
            component: ClientPage,
        },
    ]
});

export default router;
