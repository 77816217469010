<template>
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-12">
                <h3>Client Stocks</h3>
            </div>
        </div>
        <div class="row">
            <div class="offset-md-2 col-md-8">
                <div v-if="client" class="bg-light p-4">
                    <div class="mb-2">Stocks for <em>{{ client.user_name}}</em></div>

                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Company</th>
                            <th scope="col">Volume</th>
                            <th scope="col">Purchase Price</th>
                            <th scope="col">Current Price</th>
                            <th scope="col">Gain/Loss</th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr v-if="clientPurchases.length > 0" v-for="purchase in clientPurchases" :key="purchase.id">
                            <td>
                                {{ purchase.stock.company_name }}
                            </td>
                            <td>
                                {{ purchase.volume }}
                            </td>
                            <td>
                                €{{ purchase.purchase_price_whole }}
                            </td>
                            <td>
                                €{{ purchase.stock.unit_price_whole }}
                            </td>
                            <td>
                                <span :class="getChangeClass(purchase.gain_or_loss)">
                                 {{ getChangeText(purchase.gain_or_loss) }}
                                </span>
                            </td>
                        </tr>
                        <div v-else class="text-center">
                            No Purchases by {{ client.user_name}} yet.
                        </div>
                        </tbody>
                    </table>
                </div>
                <div v-else>Loading...</div>
                <div v-if="loaded" class="d-flex flex-row justify-content-end">
                    <div class="d-flex flex-column">
                        <div>
                            <span>Total</span>:
                            <span :class="getChangeClass(total)"> {{ getChangeText(total) }}</span>
                        </div>
                        <div>
                            <span>Invested</span>:
                            <span>{{ getChangeText(invested, true) }}</span>
                        </div>
                        <div>
                            <span>Performance</span>:
                            <span :class="getChangeClass(portfolioPerformance)">{{ getPercentText(portfolioPerformance) }}</span>
                        </div>
                        <div>
                            <span>Cash Balance</span>:
                            <span>{{ getChangeText(cashBalance, true) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceFormatter from "../mixins/PriceFormatter";

export default {
    name: "ClientStocks",
    mixins: [PriceFormatter],
    data() {
        return {
            client: null,
            clientPurchases: [],
            loaded: false,
        };
    },
    async created() {
        const {data: {data}} = await axios.get(`/api/clients/${this.$route.params.id}`);

        this.client = data;

        if (this.client) {
            const {data: {data: purchases}} = await axios.get(`/api/clients/${this.client.id}/purchases`);

            this.clientPurchases = purchases;
        }

        this.loaded = true;
    },
    methods: {
        getPercentText(value, withoutPositiveSign) {
            if (value < 0.0) {
                return `- ${Math.abs(value).toFixed(2)}%`
            } else if (value == 0.0) {
                return `${Math.abs(value).toFixed(2)}%`;
            }

            return withoutPositiveSign
                ? `${Math.abs(value).toFixed(2)}%`
                : `+ ${Math.abs(value).toFixed(2)}%`;
        },
    },
    computed: {
      total() {
          if (this.clientPurchases.length < 1) {
              return 0.00;
          }

          let total = 0.00;

          this.clientPurchases.forEach((client) => {
              total += Number(client.gain_or_loss);
          });

          return total;
      },
      invested() {
          if (this.clientPurchases.length < 1) {
              return 0.00;
          }

          let total = 0.00;

          this.clientPurchases.forEach((client) => {
              total += (client.volume * Number(client.purchase_price_whole))
          });

          return total;
      },
      portfolioPerformance() {
          if (this.clientPurchases.length < 1) {
              return 0.00;
          }

          return (this.total / this.invested) * 100;
      },
      cashBalance() {
          if (!this.client) {
              return 0.00;
          }

          return this.client.balance_whole;
      },
    },
};
</script>

<style scoped>

</style>
