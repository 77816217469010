<template>
    <div>
        <div>
            <b-navbar toggleable="lg" type="light" variant="light">
                <div class="container">
                    <b-navbar-brand href="#">
                        <router-link class="nav-link text-dark" :to="{name: 'home'}">Stovip</router-link>
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item class="active">
                            <router-link class="nav-link" :to="{name: 'home'}">Stocks</router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link class="nav-link" :to="{name: 'clients'}">Clients</router-link>
                        </b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
                </div>
            </b-navbar>
        </div>
        <div class="container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
name: "App"
}
</script>

<style scoped>

</style>
